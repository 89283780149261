import { Component } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html'
})
export class TitleComponent {
  constructor(private router: Router, private route: ActivatedRoute, private titleService: Title) {
    this.router.events
      .subscribe(event => {
        let currentRoute = this.route.root;
        let title = '';
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(children_route => {
            if (children_route.outlet === 'primary') {
              title = children_route.snapshot.data.breadcrumb;
              currentRoute = children_route;
            }
          });
        } while (currentRoute);
        // this.titleService.setTitle( 'Mash Able | ' + title );
      });
  }
}
