import {Routes} from '@angular/router';
import {WithBgImageComponent} from './with-bg-image/with-bg-image.component';
import {LoginLayoutComponent} from './login-layout.component';
import {StudentLoginComponent} from './students/student-login.component';
import {GuardianLoginComponent} from './guardian/guardian-login.component';
import {TeacherLoginComponent} from './teachers/teacher-login.component';

export const LoginRoutes: Routes = [
    {
        path: 'login',
        component: LoginLayoutComponent,
        data: {
            breadcrumb: 'Login'
        },
        children: [
            {   path: '',
                component: WithBgImageComponent,
                pathMatch: 'full',
                data: { breadcrumb: 'Login'}
            },
          {   path: 'students',
            component: StudentLoginComponent
          },
          {   path: 'guardians',
            component: GuardianLoginComponent
          },
          {   path: 'teachers',
            component: TeacherLoginComponent
          }
        ]
    }
];
