/**
 * Created by LifeSoft on 09/01/18.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {HttpClientModule} from '@angular/common/http';
import {StudentLayoutRoutingModule} from './students-layout-routing.module';
import {StudentsLayoutComponent} from './students-layout.component';
import {StudentDashboardComponent} from './dashboard/student-dashboard.component';
import {StudentProfileComponent} from './profile/student-profile.component';
import {StudentSettingsComponent} from './settings/student-settings.component';
import {StudentTLMDataComponent} from './tlm-data/tlm-data.component';
import {TagGuardService} from '../../shared/guard/tag-guard.service';

@NgModule({
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    SharedModule,
    StudentLayoutRoutingModule,
    HttpClientModule],
  declarations: [StudentsLayoutComponent, StudentDashboardComponent, StudentProfileComponent,
                StudentSettingsComponent, StudentTLMDataComponent],
  providers: [TagGuardService]
})
export class StudentsLayoutModule { }
