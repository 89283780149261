/**
 * Created by LifeSoft on 13/02/18.
 */
import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ErrorService} from '../../../shared/services/util/error.service';
import {NotificationsService} from 'angular2-notifications';
import {AuthService} from '../../../shared/services/auth.service';
import {DefaultApiService} from '../../../shared/services/default-api.service';
import {Title} from '@angular/platform-browser';
import {User} from '../../../models/user';
import {TeacherLearnerMaterial, TeacherLearnerMaterialResponse} from '../../../models/teacher-learner-material';
import {MicService} from '../../../models/util/mic.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidateFormService} from '../../../shared/services/validate-form.service';
import {EduClass} from '../../../models/edu-class';
import {Week} from '../../../models/week';
import {Subject} from '../../../models/subject';
import {MainResponse} from '../../../models/response/main-response';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {environment} from '../../../../environments/environment';
import {DefaultHeaderService} from '../../../shared/services/util/default-header.service';
@Component({
    selector: 'app-teacher-learner-material',
    templateUrl: './teacher-learner-material.component.html',
})
export class TeacherLearnerMaterialComponent implements OnInit {
  title = 'Teacher Learner Material';
  loading = false;
  loginUser: User;
  tlmForm: FormGroup;
  teacherLearnerMaterials: TeacherLearnerMaterial[];
  tlmModalTitle = 'Add TLM Details';
  tlmBusy = false;
  saveTopic = true;
  data: any;
  rowsOnPage = 10;
  filterQuery = '';
  sortBy = '';
  sortOrder = 'desc';
  classesData: any;
  eduClasses: EduClass[];
  weeksData: any;
  weeks: Week[];
  subjects: Subject[];
  selectedTLM: TeacherLearnerMaterial;
  uploader: FileUploader;
  base_url = environment.base_url;
  uploadTLMModalTitle = '';
  uploadAcceptType = 'image/*';
  viewTLMDetails = '';
  constructor(private errorService: ErrorService,
              private notificationService: NotificationsService,
              private _auth: AuthService,
              private defaultApi: DefaultApiService,
              private _title: Title,
              private micService: MicService,
              private formBuilder: FormBuilder,
              private formValidator: ValidateFormService,
              private defaultHeaderService: DefaultHeaderService) { }
  ngOnInit(): void {
    this._title.setTitle(this.title);
    this.loginUser = this._auth.getLoginUser();
    this.onPageStart();
  }
  onPageStart(): void {
    this.getMaterials();
  }
  onRefresh(refresh: boolean): void {
    if (refresh) {
      this.onPageStart();
    }
  }
  getMaterials(): void {
    if (this.loginUser.details) {
      const url = 'api/v1/teacher-learner-materials?employee_id=' + this.loginUser.details.id;
      this.loading = true;
      this.defaultApi.getAll(url)
        .subscribe((response: TeacherLearnerMaterialResponse) => {
          this.loading = false;
          if (response.status) {
            this.teacherLearnerMaterials = response.data.tlms;
            this.eduClasses = response.data.classes;
            this.weeks = response.data.weeks;
            this.subjects = response.data.subjects;
            this.onBuildTLMTableData(this.teacherLearnerMaterials);
            this.onBuildClassesData(this.eduClasses);
            this.onBuildWeeksData(this.weeks);
          } else {
            this.notificationService.warn(this.title, response.message);
          }
        }, (error) => {
            this.loading = false;
            this.errorService.handleError(error);
        });
    } else {
      this.notificationService.warn(this.title, 'This Page is For Teachers ONLY!');
    }
  }
  onBuildTLMTableData(tlms: TeacherLearnerMaterial[]): void {
    this.data = [];
    let counter = 1;
    for (const tlm of tlms) {
      this.data.push({
        id: tlm.id,
        _number: counter++,
        name: tlm.topic,
        weeks: tlm.weeks,
        classes: tlm.classes,
        employeeName: this.micService.getFullNameDefault(tlm.employee),
        subject: tlm.subject.code,
        tlm: tlm
      });
    }
  }
  onOPenTopicModal(modal: any): void {
    this.saveTopic = true;
    this.tlmModalTitle = 'Add TLM Details';
    this.onCreateTLMForm();
    modal.show();
  }
  onEditTopic(tlm: TeacherLearnerMaterial, modal: any): void {
    this.saveTopic = false;
    this.tlmModalTitle = 'Edit '  + tlm.topic;
    this.selectedTLM = tlm;
    this.onCreateTLMForm();
    this.onPatchTLMForm();
    modal.show();
  }
  onBuildClassesData(classes: EduClass[]): void {
    this.classesData = [];
    for (const eduClass of classes) {
      this.classesData.push({value: eduClass.id, label: eduClass.name});
    }
  }
  onBuildWeeksData(weeks: Week[]): void {
    this.weeksData = [];
    for (const week of weeks) {
      this.weeksData.push({value: week.id, label: week.name});
    }
  }
  onCreateTLMForm(): void {
    this.tlmForm = this.formBuilder.group({
      topic: ['', [Validators.required]],
      subject_id: ['', [Validators.required]],
      edu_classes_id: ['', [Validators.required]],
      description: [''],
      weeks_id: ['', [Validators.required]]
    });
  }
  onPatchTLMForm(): void {
    this.tlmForm = this.formBuilder.group({
      topic: [this.selectedTLM.topic, [Validators.required]],
      subject_id: [this.selectedTLM.subject_id, [Validators.required]],
      edu_classes_id: [this.getTLMClassArray(this.selectedTLM.classes), [Validators.required]],
      description: [this.selectedTLM.description ? this.selectedTLM.description : ''],
      weeks_id: [this.getTLMWeekArray(this.selectedTLM.weeks), [Validators.required]]
    });
  }
  getTLMClassArray(eduClasses: EduClass[]): any[] {
    const classes = [];
      for (const eduClass of eduClasses) {
        classes.push(eduClass.id);
      }
      return classes;
  }
  getTLMWeekArray(weeks: Week[]): any[] {
    const weeksArray = [];
    for (const week of weeks) {
      weeksArray.push(week.id);
    }
    return weeksArray;
  }
  isInputValid(formControl: AbstractControl): boolean {
    return this.formValidator.isInputValid(formControl);
  }
  labelErrorStyle(formControl: AbstractControl): string {
    return this.formValidator.complainAboutErrorForLabel(formControl);
  }
  formControlErrorStyle(formControl: AbstractControl): string {
    return this.formValidator.complainAboutError(formControl);
  }
  getTLMBody(): any {
    return {
      topic: this.tlmForm.get('topic').value,
      description: this.tlmForm.get('description').value,
      weeks_id: this.tlmForm.get('weeks_id').value,
      edu_classes_id: this.tlmForm.get('edu_classes_id').value,
      subject_id: this.tlmForm.get('subject_id').value,
      employee_id: this.loginUser.details.id
    };
  }
  onTLMSubmit(modal: any): void {
    const body = this.getTLMBody();
    if (this.saveTopic) {
      const url = 'api/v1/teacher-learner-materials/store';
      this.tlmBusy = true;
      this.defaultApi.save(body, url)
        .subscribe((response: MainResponse) => {
          this.tlmBusy = false;
          if (response.status) {
            this.notificationService.success(this.title, 'TLM Saved!');
            modal.hide();
            this.getMaterials();
          } else {
            this.notificationService.warn(this.title, response.message);
          }
        }, (error) => {
          this.tlmBusy = false;
          this.errorService.handleError(error);
        });
    } else {
      const url = 'api/v1/teacher-learner-materials/update/' + this.selectedTLM.id
                  + '?employee_id=' + this.loginUser.details.id;
      this.tlmBusy = true;
      this.defaultApi.update(body, url)
        .subscribe((response: MainResponse) => {
          this.tlmBusy = false;
          if (response.status) {
            this.notificationService.success(this.title, 'TLM Updated!');
            modal.hide();
           this.getMaterials();
          } else {
            this.notificationService.warn(this.title, response.message);
          }
        }, (error) => {
          this.tlmBusy = false;
          this.errorService.handleError(error);
        });
    }
  }
  getClassCurrentClassSubjects(idArray: any): Subject[] {
      const id = idArray.value;
      for (const eduClass of this.eduClasses) {
        if (eduClass.id === id) {
          this.subjects =  eduClass.currentSubjects;
        }
      }
      if (this.subjects.length > 0) {
        this.tlmForm.patchValue({subject_id: this.subjects[0].id});
      }
    return [];
  }
  imageUpload(tlm: TeacherLearnerMaterial, modal: any): void {
    this.uploadTLMModalTitle = 'Upload Image to ' + tlm.topic;
    this.uploadAcceptType = 'image/*';
    this.uploader = new FileUploader({
      url: this.base_url + 'api/v1/teacher-learner-materials/save-image/' + tlm.id,
      isHTML5: true,
      headers: this.defaultHeaderService.getFileUploaderHeaders()
    });
    let uploadError = false;
    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
    });
   this.uploader.onCompleteItem = ((item, response) => {
     const data = JSON.parse(response);
     if (!data.status) {
       item.isUploaded = false;
       item.isSuccess = false;
       item.isError = true;
       uploadError = true;
       this.notificationService.warn('TLM Upload', 'Failed to Upload ' + item.file.name , {timeOut: 10000});
     }
   });

   this.uploader.onCompleteAll = (() => {
     if (!uploadError) {
       this.notificationService.success(this.title, 'Item(s) Uploaded Successfully');
     }
   });

    modal.show();
  }

  videoUpload(tlm: TeacherLearnerMaterial, modal: any): void {
    this.uploadTLMModalTitle = 'Upload Video to ' + tlm.topic;
    this.uploadAcceptType = 'video/mp4,video/x-m4v,video/*';
    this.uploader = new FileUploader({
      url: this.base_url + 'api/v1/teacher-learner-materials/save-video/' + tlm.id,
      isHTML5: true,
      headers: this.defaultHeaderService.getFileUploaderHeaders()
    });
    let uploadError = false;
    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
    });
    this.uploader.onCompleteItem = ((item, response) => {
      const data = JSON.parse(response);
      if (!data.status) {
        item.isUploaded = false;
        item.isSuccess = false;
        item.isError = true;
        uploadError = true;
        this.notificationService.warn('TLM Upload', 'Failed to Upload ' + item.file.name , {timeOut: 10000});
      }
    });

    this.uploader.onCompleteAll = (() => {
      if (!uploadError) {
        this.notificationService.success(this.title, 'Item(s) Uploaded Successfully');
      }
    });

    modal.show();
  }

  PDFUpload(tlm: TeacherLearnerMaterial, modal: any): void {
    this.uploadTLMModalTitle = 'Upload PDF to ' + tlm.topic;
    this.uploadAcceptType = 'application/pdf';
    this.uploader = new FileUploader({
      url: this.base_url + 'api/v1/teacher-learner-materials/save-pdf/' + tlm.id,
      isHTML5: true,
      headers: this.defaultHeaderService.getFileUploaderHeaders()
    });
    let uploadError = false;
    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
    });
    this.uploader.onCompleteItem = ((item, response) => {
      const data = JSON.parse(response);
      if (!data.status) {
        item.isUploaded = false;
        item.isSuccess = false;
        item.isError = true;
        uploadError = true;
        this.notificationService.warn('TLM Upload', 'Failed to Upload ' + item.file.name , {timeOut: 10000});
      }
    });

    this.uploader.onCompleteAll = (() => {
      if (!uploadError) {
        this.notificationService.success(this.title, 'Item(s) Uploaded Successfully');
      }
    });

    modal.show();
  }

  PPTUpload(tlm: TeacherLearnerMaterial, modal: any): void {
    this.uploadTLMModalTitle = 'Upload Power Point to ' + tlm.topic;
    this.uploadAcceptType = '.ppt,.pptx';
    this.uploader = new FileUploader({
      url: this.base_url + 'api/v1/teacher-learner-materials/save-power-point/' + tlm.id,
      isHTML5: true,
      headers: this.defaultHeaderService.getFileUploaderHeaders()
    });
    let uploadError = false;
    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
    });
    this.uploader.onCompleteItem = ((item, response) => {
      const data = JSON.parse(response);
      if (!data.status) {
        item.isUploaded = false;
        item.isSuccess = false;
        item.isError = true;
        uploadError = true;
        this.notificationService.warn('TLM Upload', 'Failed to Upload ' + item.file.name , {timeOut: 10000});
      }
    });
    this.uploader.onCompleteAll = (() => {
      if (!uploadError) {
        this.notificationService.success(this.title, 'Item(s) Uploaded Successfully');
      }
      this.getMaterials();
    });

    modal.show();
  }
  onViewTLMData(tlm: TeacherLearnerMaterial, modal: any): void {
    this.selectedTLM = tlm;
    this.viewTLMDetails = this.selectedTLM.topic + ' | Uploaded Data.';
    modal.show();

  }
  downloadFile(fileName: string, id: number): void {
    const url = 'api/v1/teacher-learner-materials/download-file/' + id;
    this.loading = true;
    this.defaultApi.getFile(url).subscribe((response) => {
      this.loading = false;
      if (response.type !== 'application/json') {
        const a = document.createElement('a'),
          fileURL = URL.createObjectURL(response);
        // a.style = "display:none";
        a.href = fileURL;
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      } else {
        this.notificationService.warn('TLM Data', 'Failed to Download TLM');
      }
    }, (error) => {
      this.loading = false;
      this.errorService.handleError(error);
    });
  }
}
