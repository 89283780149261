import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { Error4Component } from './error/error4/error4.component';
import { LoginModule } from './authentication/login/login.module';
import { EmployeesLayoutModule } from './employee-layout/employees/employees-layout.module';
import { StudentsLayoutModule } from './student-layout/students/students-layout.module';
import { GuardianLayoutModule } from './guardian-layout/guardian/guardian-layout.module';
import { TeacherLayoutModule } from './teacher-layout/teachers/teacher-layout.module';
import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ScrollModule } from './scroll/scroll.module';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import {SimpleNotificationsModule} from 'angular2-notifications';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    Error4Component
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LoginModule,
    EmployeesLayoutModule,
    StudentsLayoutModule,
    GuardianLayoutModule,
    TeacherLayoutModule,
    SharedModule,
    SimpleNotificationsModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ScrollModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports: [ScrollModule],
  providers: [
      { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
