import { Injectable } from '@angular/core';
import { Employee } from '../../../models/employee';

@Injectable()
export class DataService {
  private dataStore: any[] = [];

  setData(key: string, data: any): void {
    this.dataStore[key] = data;
  }

  getData(key: string): any[] {
    return  this.dataStore[key] || [];
  }

  resetData(): void {
    this.dataStore = [];
  }
}
