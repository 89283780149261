import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {DefaultApiService} from '../default-api.service';
@Injectable()
export class ActivityResolverService implements Resolve<any> {
  constructor(private defaultApi: DefaultApiService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const url = 'api/v1/user-activities';
    return this.defaultApi.getAll(url);
  }

}
