/**
 * Created by LifeSoft on 20/11/17.
 */
import {Injectable} from '@angular/core';
@Injectable()
export class TitleService {
    getDefualtTitles(): any {
        return [
            'Mr.',
            'Mrs.',
            'Ms.',
            'Dr.',
            'Prof.',
            'Rev.'
        ];
    }
}
