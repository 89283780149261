import { Injectable } from '@angular/core';

@Injectable()
export class EduArray {
    findIndex(_id: number, _data: any[]): number {
        if (!_data || _data.length === 0) {
          return -1;
        }
        return _data.findIndex((data) => data.id === _id);
    }
  isKey(_key: string, _value: any, _data: any[]): boolean {
    if (!_data || _data.length === 0) {
      return false;
    }
    return _data.findIndex((data) => data[_key] === _value) > -1;
  }
    findIndexByKey(_key: string, _value: any, _data: any[]): number {
        if (!_data || _data.length === 0) {
          return -1;
        }
        return _data.findIndex((data) => data[_key] === _value);
    }
    findTotal(_key: string, _data: any[]): number {
      if (!_data || _data.length === 0) {
        return 0;
      }
      return _data.map((data) => data[_key]).reduce((acc, cur) => acc + cur, 0);
    }
  studentTransactionTotal(_key: string, _data: any[]): number {
    if (!_data || _data.length === 0) {
      return 0;
    }
     return _data.filter((filItem) => {
                    return filItem.type === _key;
                }).map((data) => {
                    return data.amount;
                }).reduce((acc, cur) => acc + cur, 0);

  }
  whereSum(_key: string, _value, _key_to_sum, _data: any[]): number {
    if (!_data || _data.length === 0) {
      return 0;
    }
     return _data.filter((filItem) => {
                    return filItem[_key] === _value;
                }).map((data) => {
                    return data[_key_to_sum];
                }).reduce((acc, cur) => acc + cur, 0);

  }
}
