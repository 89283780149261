/**
 * Created by LifeSoft on 09/01/18.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
@Injectable()
export class StudentGuardService implements CanActivate, CanActivateChild {
  constructor(private router: Router, private _auth: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url = state.url;
    return this.checkLogin(url);
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
  checkLogin(url: string): boolean {
    if (this._auth.isLogin() && this._auth.hasSameUserType('student')) {
      return true;
    }
    this._auth.redirectUrl = url;
    this.router.navigate(['/login/students']);
    return false;
  }
}
