/**
 * Created by LifeSoft on 15/01/18.
 */
import {Component, OnInit} from '@angular/core';
import {fadeInOutTranslate} from '../../../shared/elements/animation';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {PasswordBody, User} from '../../../models/user';
import {DefaultApiService} from '../../../shared/services/default-api.service';
import {MicService} from '../../../models/util/mic.service';
import {ValidateFormService} from '../../../shared/services/validate-form.service';
import {NotificationsService} from 'angular2-notifications';
import {Title} from '@angular/platform-browser';
import {ErrorService} from '../../../shared/services/util/error.service';
import {AuthService} from '../../../shared/services/auth.service';
import {MainResponse} from '../../../models/response/main-response';

@Component({
  selector: 'app-teacher-account-settings',
  templateUrl: './teacher-account-settings.component.html',
  animations: [fadeInOutTranslate]
})
export class TeacherAccountSettingsComponent implements OnInit {
  title = 'Teacher Account Settings';
  cardSubtitle = 'Teacher Account Settings';
  passwordForm: FormGroup;
  passwordBusy = false;
  loginUser: User;
  constructor(private defaultApi: DefaultApiService,
              private formValidator: ValidateFormService,
              private notificationService: NotificationsService,
              private formBuilder: FormBuilder,
              private _title: Title,
              private errorService: ErrorService,
              private _auth: AuthService) { }
  ngOnInit(): void {
    this._title.setTitle(this.title);
    this.onPageStart();
  }
  onPageStart(): void {
    this.loginUser = this._auth.getLoginUser();
    this.createPasswordForm();
  }
  onPageRefresh(refresh: boolean): void {
    if (refresh) {
      this.onPageStart();
    }
  }
  createPasswordForm(): void {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, this.confirmPassword]]
    });
  }
  confirmPassword(formControl: AbstractControl): any {
    if (!formControl.parent || !formControl) {return; }
    const password = formControl.parent.get('password');
    const confirm_password = formControl.parent.get('confirm_password');
    if (!password || !confirm_password) {return; }
    if (password.value !== confirm_password.value) {return {invalid: true}; }
  }
  formLabelError(formControl: AbstractControl): string {
    return this.formValidator.complainAboutErrorForLabel(formControl);
  }
  formControlError(formControl: AbstractControl): string {
    return this.formValidator.complainAboutError(formControl);
  }
  isFormControlInvlaid(formControl: AbstractControl): boolean {
    return this.formValidator.isInputValid(formControl);
  }
  buildPasswordBody(): PasswordBody {
    const passwordBody = new PasswordBody();
    passwordBody.password = this.passwordForm.get('password').value;
    return passwordBody;
  }
  onChangePassword(): void {
    const url = 'api/v1/users/change-password/' + this.loginUser.id;
    const body = this.buildPasswordBody();
    this.passwordBusy = true;
    this.defaultApi.update(body, url)
      .subscribe((response: MainResponse) => {
        this.passwordBusy = false;
        if (response.status) {
          this.onPageStart();
          this.notificationService.success(this.title, response.message);
        } else {
          this.notificationService.warn(this.title, response.message);
        }
      }, (error) => {
        this.passwordBusy = false;
        this.errorService.handleError(error);
      });
  }
}
