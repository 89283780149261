import {Injectable} from '@angular/core';
import {Role} from './role';
import {EduNotification} from './edu-notification.model';
import {Tag} from './tag.model';
/**
 * Created by LifeSoft on 27/10/17.
 */
@Injectable()
export class User {
    public id: number;
    public username: string;
    public email: string | null;
    public raw_password?: string|null;
    public first_name: string;
    public last_name: string;
    public middle_name: string|null;
    public level: string;
    public key: string | null;
    public active: boolean;
    public avatar?: string;
    public details?: any;
    public roles: Role[] | null;
    public tags: Tag[] |null;
    public eduNotifications: EduNotification[] |null;
}
@Injectable()
export class PasswordBody {
  password: string;
}
