/**
 * Created by LifeSoft on 08/02/18.
 */
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Injectable} from '@angular/core';
import {RoleManagerService} from '../services/role-manager.service';
import {NotificationsService} from 'angular2-notifications';
@Injectable()
export class TeacherGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router,
              private _auth: AuthService,
              private roleService: RoleManagerService,
              private notificationService: NotificationsService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url = state.url;
    return this.checkLogin(url);
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
  checkLogin(url: string): boolean {
    if (this._auth.isLogin() &&
        this._auth.hasSameUserType('employee')) {
      return true;
    }
    this._auth.redirectUrl = url;
    this.router.navigate(['/login/teachers']);
    return false;
  }
}
