/**
 * Created by LifeSoft on 02/11/17.
 */
import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {HttpHeaders} from '@angular/common/http';
@Injectable()
export class DefaultHeaderService {
    constructor(private auth: AuthService) { }
    getDefaultAuthHeaders(): HttpHeaders {
        const userToken = this.auth.getLoginUser().key;
        const headerJson = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: userToken
        };
        return new HttpHeaders(headerJson);
    }
  getDefaultAuthBlobHeaders(): HttpHeaders {
    const userToken = this.auth.getLoginUser().key;
    const headerJson = {
      'Content-Type': 'application/json',
      'Authorization': userToken
    };
    return new HttpHeaders(headerJson);
  }
    getDefaultFormAuthHeaders(): HttpHeaders {
        const userToken = this.auth.getLoginUser().key;
        const headerJson = {
            'Accept': 'application/json',
            'Content-Type': 'undefined',
            'Authorization': userToken
        };
        return new HttpHeaders(headerJson);
    }
    getDefaultUpdateFormAuthHeaders(): HttpHeaders {
        const userToken = this.auth.getLoginUser().key;
        const headerJson = {
            'Accept': 'application/json',
            'Authorization': userToken
        };
        return new HttpHeaders(headerJson);
    }
    getDefaultAuthMultiHeaders(): HttpHeaders {
        const userToken = this.auth.getLoginUser().key;
        const headerJson = {
            'Accept': 'application/json',
            'enctype': 'multipart/form-data',
            'Authorization': userToken
        };
        return new HttpHeaders(headerJson);
    }
    getFileUploaderHeaders(): any[] {
      const userToken = this.auth.getLoginUser().key;
      return [{name: 'Accept', value: 'application/json'},
              {name: 'enctype', value: 'multipart/form-data'},
              {name: 'Authorization', value: userToken}];
    }
    getDefaultHeaders(): HttpHeaders {
        const headerJson = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        return new HttpHeaders(headerJson);
    }
}
