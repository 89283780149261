/**
 * Created by LifeSoft on 08/06/18.
 */
import {Injectable} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';
@Injectable()
export class BlobJSON {
  constructor(private notificationService: NotificationsService) { }

  handleBlob(response: Blob, title?: string): void {
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      if (typeof myReader.result === 'string') {
        const data = JSON.parse(myReader.result);
        title = title ? title : 'PDF Error';
        this.notificationService.warn(title, data.message);
      }
    };
    myReader.readAsText(response);
  }
}
