/**
 * Created by LifeSoft on 30/07/18.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
@Injectable()
export class TagGuardService implements CanActivate, CanActivateChild {
  constructor(private router: Router, private _auth: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url = state.url;
    return this.checkTags(url);
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
  checkTags(url: string): boolean {
    const loginUser = this._auth.getLoginUser();
    if(loginUser && loginUser.tags.length === 0){
      return true;
    }
    this.router.navigate(['/students']);
    return false;
  }
}
