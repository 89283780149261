import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TeacherGuard} from '../../shared/guard/teacher-guard.guard';
import {TeacherLayoutComponent} from './teacher-layout.component';
import {TeacherDashboardComponent} from './dashboard/teacher-dashboard.component';
import {TeacherAccountSettingsComponent} from './settings/teacher-account-settings.component';
import {TeacherLearnerMaterialComponent} from './teacher-learner-material/teacher-learner-material.component';
/**
 * Created by LifeSoft on 08/02/18.
 */
const routes: Routes = [
  {
    path: 'teachers',
    component: TeacherLayoutComponent,
    canActivate: [TeacherGuard],
    pathMatch: 'prefix',
    children: [
      {path: '', canActivateChild: [TeacherGuard],
        children: [
          {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
          {path: 'dashboard', component: TeacherDashboardComponent},
          {path: 'account-settings', component: TeacherAccountSettingsComponent},
          {path: 'online-examination',
            loadChildren: 'src/app/teacher-layout/teachers/online-examination/online-examination.module#OnlineExaminationModule'},
          {path: 'teacher-learner-materials/view-all', component: TeacherLearnerMaterialComponent},
        ]
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule]
})
export class TeacherRoutingModule { }
