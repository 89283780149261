/**
 * Created by LifeSoft on 21/06/18.
 */
import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 100, completeWords = true, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, 100).lastIndexOf(' ');
    }
    if (value.length <= limit) {
      ellipsis = '';
    }
    return `${value.substr(0, limit)}${ellipsis}`;
  }

}
