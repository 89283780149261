/**
 * Created by LifeSoft on 12/01/18.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {HttpClientModule} from '@angular/common/http';
import {GuardianLayoutRoutingModule} from './guardian-layout-routing.module';
import {GuardianLayoutComponent} from './guardian-layout.component';
import {GuardianDashboardComponent} from './dashboard/guardian-dashboard.component';
import {GuardianAccountSettingsComponent} from './settings/guardian-account-settings.component';
import {GuardianResultCheckerModule} from './result/guardian-result-checker.module';
@NgModule({
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    SharedModule,
    GuardianLayoutRoutingModule,
    HttpClientModule],
  declarations: [GuardianLayoutComponent,
                GuardianDashboardComponent,
                GuardianAccountSettingsComponent]
})
export class GuardianLayoutModule { }
