/**
 * Created by LifeSoft on 19/12/17.
 */
import {Injectable} from '@angular/core';
import {DefaultApiService} from './default-api.service';
import {Observable} from 'rxjs';
@Injectable()
export class ExaminationService extends DefaultApiService {
  getClassCurrentExamination(id: number): Observable<any> {
    const url = 'api/v1/examinations/current-records-by-class/' + id;
    return this.getById(url);
  }

}
