/**
 * Created by LifeSoft on 28/11/17.
 */
import {Injectable} from '@angular/core';
import {DefaultApiService} from './default-api.service';
import {Observable} from 'rxjs';
import {Subject, SubjectBody} from '../../models/subject';
@Injectable()
export class SubjectService extends DefaultApiService {
    getSubjects(): Observable<any> {
        const url = 'api/v1/subjects';
        return this.getAll(url);
    }
    getSubjectById(id: number): Observable<any> {
        const url = 'api/v1/subjects/' + id;
        return this.getById(url);
    }
    saveSubject(body: SubjectBody): Observable<any> {
        const url = 'api/v1/subjects/store';
        return this.save(body, url);
    }
    updateSubject(id: number, body: SubjectBody): Observable<any> {
        const url = 'api/v1/subjects/update/' + id;
        return this.update(body, url);
    }
    hasSubject(subject: Subject, subjects: any): boolean {
        if (subject === null || subjects.length === 0) {
            return false;
        }
        for (const $subject of subjects) {
            if ($subject === subject.id) {
                return true;
            }
        }
        return false;
    }
}
