/**
 * Created by LifeSoft on 12/01/18.
 */
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ErrorService} from '../../../shared/services/util/error.service';
import {NotificationsService} from 'angular2-notifications';
import {DefaultApiService} from '../../../shared/services/default-api.service';
import {Student} from '../../../models/student';
// import {Subscription} from 'rxjs';
import {AcademicYear} from '../../../models/academic-year';
import {CurrentAcademicCalendar} from '../../../models/util/current-academic-calendar';
import {Guardian} from '../../../models/guardian';
import {AuthService} from '../../../shared/services/auth.service';
import {User} from '../../../models/user';
import {GuardianAnalyticResponse} from '../../../models/response/guardian-analytics-response';
import {MicService} from '../../../models/util/mic.service';
import {EduColorGeneratorService} from '../../../shared/services/util/edu-color-generator.service';
import {BarChart, BarChartDatasets} from '../../../models/util/bar-chart';
import {environment} from '../../../../environments/environment';
import {WardSelectedAnouncer} from '../../../shared/services/util/guardian-ward-selector.service';
@Component({
  selector: 'app-guardian-dashboard',
  templateUrl: './guardian-dashboard.component.html',
	providers: [WardSelectedAnouncer]
})
export class GuardianDashboardComponent implements OnInit {
  title = 'Guardian Dashboard';
  wards: Student[];
  guardianBusy = false;
  wardResultBusy = false;
  academicYears: AcademicYear[];
  selectedAcademicYear: AcademicYear;
  currentCalendar: CurrentAcademicCalendar;
  guardian: Guardian;
  loginUser: User;
  termLabels: string[];
  wardCardTitle = 'WARD(S)';
  barData: BarChart;
  options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero:true,
          autoSkip: false
        }
      }]
    }
  };
  base_url = environment.base_url;
  constructor(private _title: Title,
              private errorService: ErrorService,
              private notificationService: NotificationsService,
              private defaultApi: DefaultApiService,
              private _auth: AuthService,
              private micService: MicService,
              private colorGenerator: EduColorGeneratorService,
						private selectedWardAnnouncer: WardSelectedAnouncer) { }
  ngOnInit(): void {
    this._title.setTitle(this.title);
    this.loginUser = this._auth.getLoginUser();
    this.onPageStart();
  }
  onPageStart(): void {
    this.getCurrentData();
  }
  onPageRefresh(refresh): void {
    if (refresh) {
      this.onPageStart();
    }
  }
	onDefaultWardSelected(id: number): void {
    localStorage.setItem("ward", id + "");
    this.selectedWardAnnouncer.announce(id);
		setTimeout(() => window.location.href = "/guardians/results-checker/termly", 2e3);
  }
  getCurrentData(): void {
    const url = 'api/v1/guardian-analytics/current-ward-examination-history/' + this.loginUser.details.id;
    this.guardianBusy = true;
    this.defaultApi.getById(url)
      .subscribe((response: GuardianAnalyticResponse) => {
          this.guardianBusy = false;
          if (response.status) {
            this.guardian = response.data.guardian;
            this.academicYears = response.data.academicYears;
            this.currentCalendar = response.data.currentCalendar;
            this.selectedAcademicYear = this.currentCalendar.academicYear;
            this.termLabels = response.data.termsLabel;
            this.wardCardTitle = (this.guardian && this.guardian.wards.length > 1) ? 'WARDS': 'WARD';
            this.barData = {
              labels: this.termLabels,
              datasets: this.getWardAveragesArray(response.data.wardsAverages)
            };
          } else {
            this.notificationService.warn(this.title, response.message);
          }
      }, (error) =>{
          this.guardianBusy = false;
          this.errorService.handleError(error);
      });

  }
  onAcademicYearSelected(index: number): void {
    this.selectedAcademicYear = this.academicYears[index];
    this.getWardAcademicYearResult();
  }
  getWardAcademicYearResult(): void {
    const url = 'api/v1/guardian-analytics/examination-averages/'
                + this.loginUser.details.id + '/' + this.selectedAcademicYear.id;
    this.wardResultBusy = true;
    this.defaultApi.getById(url)
      .subscribe((response) => {
        this.wardResultBusy = false;
        if (response.status) {
          this.barData = {
            labels: this.termLabels,
            datasets: this.getWardAveragesArray(response.data.wardsAverages)
          };
        }else {
          this.notificationService.warn(this.title, response.message);
        }
      }, (error) => {
          this.wardResultBusy = false;
          this.errorService.handleError(error);
      });
  }
  getFullName(model: any): string{
    return this.micService.getFullname(model);
  }
  getWardAveragesArray(averages: any): BarChartDatasets[] {
    let wardAverages = [];
    for (let average of averages) {
      const backgroundColor = this.colorGenerator.generateRandomColor();
      wardAverages.push({
        label: average.label,
        data: average.averages,
        backgroundColor: [backgroundColor, backgroundColor, backgroundColor],
        borderWidth: 2
      });
    }
    return wardAverages;
  }
}
