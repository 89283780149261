/**
 * Created by LifeSoft on 16/11/17.
 */
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DefaultHeaderService} from './util/default-header.service';
import {Student} from '../../models/student';
import {PostalBody} from '../../models/util/postal-body';
import {Router} from '@angular/router';
import {GuardianBody} from '../../models/guardian';
import {AssignSubjectStudentBody} from '../../models/subject';
@Injectable()
export class StudentService {
    base_url = environment.base_url;
    constructor(private http: HttpClient,
                private header: DefaultHeaderService,
                private router: Router) { }
    getActiveStudents(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/students?active=1',
                                {headers: this.header.getDefaultAuthHeaders()});
    }
    getStudents(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/students',
                            {headers: this.header.getDefaultAuthHeaders()});
    }
  getStudentsWithClass(): Observable<any> {
    return this.http.get(this.base_url + 'api/v1/students/get-with-class',
      {headers: this.header.getDefaultAuthHeaders()});
  }
    getStudentById(id: number): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/students/' + id,
            {headers: this.header.getDefaultAuthHeaders()});
    }
    saveStudent(body: FormData): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/students/store', body,
                                {headers: this.header.getDefaultAuthMultiHeaders()});
    }
    updateStudent(id: number, body: FormData): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/students/update/' + id, body,
            {headers: this.header.getDefaultAuthMultiHeaders()});
    }
    saveStudentPostal(id: number, body: PostalBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/students/store-postal-address/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    updateStudentPostal(id: number, body: PostalBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/students/update-postal-address/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    saveStudentGuardian(id: number, body: GuardianBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/students/store-student-guardian/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    assignGuardian(id: number, body: any): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/students/assign-guardian/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    updateStudentGuardian(id: number, body: GuardianBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/students/update-student-guardian/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    assignStudentSubject(body: AssignSubjectStudentBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/students/assign-subjects', JSON.stringify(body), {
            headers: this.header.getDefaultAuthHeaders()
        });
    }
    goBackToStudentAdmission(): void {
        this.router.navigate(['/employees/students/admission']);
    }
    goBackToAllStudents(): void {
        this.router.navigate(['/employees/students/view-all']);
    }
    getStudentFullname(student: Student): string {
        const $middleName = student.middle_name ? ' ' + student.middle_name + ' ' : ' ';
        return student.last_name  + $middleName + student.first_name;
    }
}
