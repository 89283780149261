import {Breadcrumb} from '../../../models/util/breadcrumb';
import {Injectable} from '@angular/core';
/**
 * Created by LifeSoft on 31/10/17.
 */
@Injectable()

export class BreadcrumbGeneratorService {
    addToHumanResourceCrumbs(crumbs: Breadcrumb[]): Breadcrumb[] {
        let generalCrumb;
        generalCrumb = this.getDefaultHumanResourceCrumbs();

        for (const crumb of crumbs) {
                generalCrumb.push(crumb);
        }
        return generalCrumb;
    }
    getDefaultHumanResourceCrumbs() {
        return [
           this.getDashboardCrumb(),
            {icon: 'fa fa-group', url: '/employees/human-resources', title: 'Human Resource'}
        ];
    }
    addToSettingsCrumbs(crumbs: Breadcrumb[]): Breadcrumb[] {
        const settingsCrumbs =  this.getDefaultGeneralSettingsCrumbs();
        for (const crumb of crumbs) {
            settingsCrumbs.push(crumb);
        }
        return settingsCrumbs;
    }
    getDefaultGeneralSettingsCrumbs(): Breadcrumb[] {
        return [
            this.getDashboardCrumb(),
            {icon: 'fa fa-gear', url: '/employees/general-settings', title: 'General Settings'}
        ];
    }
    getDashboardCrumb(): Breadcrumb {
        return {icon: 'fa fa-dashboard-old', url: '/employees', title: 'Dashboard'};
    }
    getStudentAdmissionCrumb(): Breadcrumb[] {
        return [
            this.getDashboardCrumb(),
            {icon: '', url: '', title: 'Admit Student'}
        ]
    }
}
