/**
 * Created by LifeSoft on 03/11/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DefaultHeaderService} from './util/default-header.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
@Injectable()
export class CountryService {
    base_url = environment.base_url;
    constructor(private http: HttpClient, private header: DefaultHeaderService) { }
    getCountries(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/countries', {headers: this.header.getDefaultAuthHeaders()});
    }
    getActiveCountries(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/countries?active=1', {headers: this.header.getDefaultAuthHeaders()});
    }
}
