/**
 * Created by LifeSoft on 02/11/17.
 */
import {Injectable} from '@angular/core';
import {DefaultHeaderService} from './util/default-header.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AddDepartmentBody} from '../../models/util/add-department-body';
@Injectable()
export class EmployeeDepartmentService {
    base_url = environment.base_url;
    constructor(private header: DefaultHeaderService, private http: HttpClient) { }
    getDepartments(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/departments', {headers: this.header.getDefaultAuthHeaders()});
    }
    addDepartment(department: AddDepartmentBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/departments/store', JSON.stringify(department),
            {headers: this.header.getDefaultAuthHeaders()});
    }
    updateDepartment(id: number, department: AddDepartmentBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/departments/update/' + id, JSON.stringify(department),
            {headers: this.header.getDefaultAuthHeaders()})
    }
    getActiveDepartments(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/departments?active=1', {headers: this.header.getDefaultAuthHeaders()});
    }
}
