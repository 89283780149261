/**
 * Created by LifeSoft on 11/12/17.
 */
import {Injectable} from '@angular/core';
import {DefaultApiService} from './default-api.service';
import {Observable} from 'rxjs';
import {EduClassAssementBody} from '../../models/edu-class-assessment';
@Injectable()
export class EduClassAssessmentService extends DefaultApiService {
  url = 'api/v1/edu-class-assessments';
  getAssessment(): Observable<any> {
    return this.getAll(this.url);
  }
  saveAssessment(body: EduClassAssementBody): Observable<any> {
    return this.save(body, this.url + '/save');
  }
  updateAssessment(id: number, body: EduClassAssementBody): Observable<any> {
    return this.update(body, this.url + '/update/' + id);
  }
}
