/**
 * Created by LifeSoft on 07/05/18.
 */
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
@Injectable()
export class ConfirmDialogService {
  confirm(message?: string): Observable<boolean> {
    const confirmation =  window.confirm(message || 'Are You Sure');
    return of(confirmation);
  }
}
