import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DefaultApiService } from '../services/default-api.service';
import { Student } from 'src/app/models/student';
import { DataService } from '../services/util/data.service';
import { MicService } from 'src/app/models/util/mic.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-student',
  templateUrl: './search-student.component.html',
  styleUrls: ['./search-student.component.css']
})
export class SearchStudentComponent implements OnInit {
  students: Array<Student> = [];
  studentDataList: Array<any> = [];
  @Output() studentId = new EventEmitter<string>();
  @Input() typeOfStudents: string;
  modelChanged: Subject<string> = new Subject<string>();
  loaded = false;
  constructor(private defaultApi: DefaultApiService,
              private dataService: DataService,
              private micService: MicService) { }

  ngOnInit() {
    const data = this.dataService.getData('with_class');
    this.buildStudentDataList(data);
    if (data.length > 0) {
      this.turnOffOnlineSearch();
    }
    this.getClassWithStudent();

    this.modelChanged.pipe(debounceTime(5000)).subscribe((item) => {
      this.onSearchOnline(item);
    });
  }
  onSearchStudent(searchItem: string): void {
    //
    if (this.students.length === 0) {
      this.modelChanged.next(searchItem);
    }
  }
  getClassWithStudent(): void {
    let url = 'api/v1/students/get-with-class';
    if (this.typeOfStudents === 'active') {
      url = 'api/v1/students/get-active-with-class';
    }
    this.defaultApi.getAll(url).subscribe((response) => {
      if (response.status) {
        this.students = response.data.students;
        this.buildStudentDataList(this.students);
        this.dataService.setData('with_class', this.students);
        this.turnOffOnlineSearch();
      }

    });
  }
  turnOffOnlineSearch() {
    this.loaded = true;
    this.modelChanged.complete();
  }
  buildStudentDataList(students: Student[]): void {
    this.studentDataList = [];
    for (const stu of students) {
      this.studentDataList.push({name: this.micService.getFullNameDefault(stu) + ' [' + stu.grade.name + '] ' + stu.admission_number,
        id: stu.id});
    }
  }
  onSearchStudentDataList(value): void {
    const _student = _.findIndex(this.studentDataList, ['name', value]);
    if (_student > -1) {
      this.studentId.emit(this.studentDataList[_student].id);
    }
  }
  onSearchOnline(haystack: string): void {
    if (this.loaded === true) {
      return ;
    }
    const url = 'api/v1/canteens/search-students';
    const param = {haystack};
    this.defaultApi.getWithParams(url, param).subscribe((response) => {
      if (response.status) {
        if (this.students.length === 0) {
          this.buildStudentDataList(response.data.students);
        }
      }
    });
  }

}
