/**
 * Created by LifeSoft on 21/02/18.
 */
import {Component, OnInit} from '@angular/core';
import {ErrorService} from '../../../shared/services/util/error.service';
import {NotificationsService} from 'angular2-notifications';
import {DefaultApiService} from '../../../shared/services/default-api.service';
import {TLMData, TLMDataResponse} from '../../../models/tlm-data';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {User} from '../../../models/user';
import {AuthService} from '../../../shared/services/auth.service';
@Component({
  selector: 'app-student-tlm-data',
  templateUrl: './tlm-data.component.html'
})
export class StudentTLMDataComponent implements OnInit {
  title = 'Course Material';
  tlms: TLMData[];
  data: any;
  rowsOnPage = 10;
  filterQuery = '';
  sortBy = '';
  sortOrder = 'desc';
  loading = false;
  loginStudent: User;
  constructor(private errorService: ErrorService,
              private notificationService: NotificationsService,
              private defaultApi: DefaultApiService,
              private _title: Title,
              private _auth: AuthService) { }
  ngOnInit(): void {
    this._title.setTitle(this.title);
    this.loginStudent  = this._auth.getLoginUser();
    this.onPageStart();
  }
  onPageStart(): void {
    this.getTLMData();
  }
  onRefresh(refresh: boolean): void {
    if (refresh) {
      this.onPageStart();
    }
  }
  getTLMData(): void {

    if (this.loginStudent && this.loginStudent.details) {
      const url = 'api/v1/teacher-learner-materials/get-class-tlms/' + this.loginStudent.details.edu_class_id;
      this.loading = true;
      this.defaultApi.getAll(url)
        .subscribe((response: TLMDataResponse) => {
          this.loading = false;
          if (response.status) {
            this.tlms = response.data.tlms;
            this.buildTLMDataTable(this.tlms);
          } else {
            this.notificationService.warn('Teacher Learner Materials', response.message);
          }
        }, (error) => {
          this.loading = false;
          this.errorService.handleError(error);
        });
    } else {
      this.notificationService.warn(this.title, 'Please Login Again.');
    }

  }

  buildTLMDataTable(tlms: TLMData[]): void {
    this.data = [];
    for (const tlm of tlms) {
      this.data.push({
        name: tlm.tlmTopic.topic,
        subject: tlm.tlmTopic.subject.code + ' ' + tlm.tlmTopic.subject.name,
        type: tlm.type,
        weeks: tlm.tlmTopic.weeks,
        classes: tlm.tlmTopic.classes,
        tlm: tlm
      });
    }
  }

  downloadFile(fileName: string, id: number): void {
    const url = 'api/v1/teacher-learner-materials/download-file/' + id;
    this.loading = true;
    this.defaultApi.getFile(url).subscribe((response) => {
      this.loading = false;
      if (response.type !== 'application/json') {
        const a = document.createElement('a'),
          fileURL = URL.createObjectURL(response);
        // a.style = "display:none";
        a.href = fileURL;
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      } else {
        this.notificationService.warn('TLM Data', 'Failed to Download TLM');
      }
    }, (error) => {
      this.loading = false;
      this.errorService.handleError(error);
    });
  }
}
