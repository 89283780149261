/**
 * Created by LifeSoft on 15/01/18.
 */
import {Injectable} from '@angular/core';
@Injectable()
export class EduColorGeneratorService {
  generateColorNumber(): number {
  return Math.floor(Math.random() * 255);
  }

  generateRGBAColor($r: number, $g: number, $b: number, $a: number): string {
  return 'rgba(' + $r + ',' + $g + ',' + $b + ',' + $a + ')';
  }
  generateRandomColor(): string {
    const r = this.generateColorNumber();
    const g = this.generateColorNumber();
    const b = this.generateColorNumber();
    return this.generateRGBAColor(r, g, b, 0.4);
  }
  generateRandomBorderColor(): string {
    const r = this.generateColorNumber();
    const g = this.generateColorNumber();
    const b = this.generateColorNumber();
    return this.generateRGBAColor(r, g, b, 1);
  }

  generateRandomWithBorderColor(): Array<string> {
    const r = this.generateColorNumber();
    const g = this.generateColorNumber();
    const b = this.generateColorNumber();
    return [this.generateRGBAColor(r, g, b, 0.4), this.generateRGBAColor(r, g, b, 1)];
  }
}
