/**
 * Created by LifeSoft on 25/06/18.
 */
import {Injectable} from '@angular/core';
import {EduNotification} from '../../../models/edu-notification.model';
import {Subject} from 'rxjs';
@Injectable()
export class EduNotificationService {
  private notificationAnnouncer = new Subject<EduNotification>();
  eduNotification$ = this.notificationAnnouncer.asObservable();
  announce(edu: EduNotification): void {
    this.notificationAnnouncer.next(edu);
  }
}
